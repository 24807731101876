var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-button',{staticClass:"mb-50",attrs:{"variant":"primary","size":"sm","to":{ name: 'offr/editWebsite' }}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_vm._v(" "+_vm._s(_vm.$t('Add'))+" ")],1),_c('b-card',[_c('h4',[_vm._v(_vm._s(_vm.$t('offer.website.Yours')))]),_c('b-table',{staticClass:"table-wrap-words mt-1 rounded",attrs:{"items":_vm.webs,"fields":_vm.fields,"striped":"","responsive":"","busy":_vm.loading,"show-empty":""},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(("" + label)))+" ")]}},{key:"cell(name)",fn:function(data){return [_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],staticClass:"text-nowrap",attrs:{"to":{ name: 'offr/editWebsite', params: { websiteId: data.item.id } },"title":data.item.name}},[(data.item.name)?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm._f("truncate")(data.item.name,24, '...'))+" ")]):_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('NoName'))+" ")])])]}},{key:"cell(preview)",fn:function(ref){
var value = ref.value;
return [_c('b-button',{staticClass:"btn-icon px-25 py-25",attrs:{"variant":"flat-warning","href":((_vm.location()) + "/offer/preview/" + value),"target":"_blank"}},[_c('feather-icon',{attrs:{"icon":"EyeIcon"}})],1)]}},{key:"cell(isActive)",fn:function(ref){
var value = ref.value;
return [_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn-icon px-25 py-25",attrs:{"variant":value ? 'flat-success': 'flat-danger',"title":_vm.$t(value ? 'Active' : 'InActive')}},[_c('feather-icon',{attrs:{"icon":value ? 'CheckIcon' : 'XIcon'}})],1)]}},{key:"cell(createdBy)",fn:function(ref){
var value = ref.value;
return [_c('avatar',{attrs:{"user":value,"to":{ name: 'user', params: { userId: value.id } }}})]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
return [_c('b-dropdown',{staticClass:"dropdown-icon-wrapper text-decoration-none",attrs:{"variant":"link","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.duplicate(item)}}},[_vm._v(" "+_vm._s(_vm.$t('Duplicate'))+" ")])],1)]}},{key:"cell()",fn:function(row){return [_c('table-default-cell',{attrs:{"field":row.field,"value":row.value}})]}},{key:"empty",fn:function(){return [_c('empty-content')]},proxy:true},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle",attrs:{"variant":"primary"}})],1)]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }