<!--eslint-disable-->

<template>
  <div>
    <b-button
      variant="primary"
      size="sm"
      class="mb-50"
      :to="{ name: 'offr/editWebsite' }"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      {{ $t('Add') }}
    </b-button>

    <b-card>
      <h4>{{ $t('offer.website.Yours') }}</h4>

      <b-table
        :items="webs"
        class="table-wrap-words mt-1 rounded"
        :fields="fields"
        striped
        responsive
        :busy="loading"
        show-empty
      >
        <!--    Head    -->
        <template #head()="{ label }">
          {{ $t(`${label}`) }}
        </template>

        <!--    Name    -->
        <template #cell(name)="data">
          <router-link
            v-b-tooltip.hover.v-primary
            :to="{ name: 'offr/editWebsite', params: { websiteId: data.item.id } }"
            class="text-nowrap"
            :title="data.item.name"
          >
            <span
              v-if="data.item.name"
              class="text-nowrap"
            >
              {{ data.item.name | truncate(24, '...') }}
            </span>
            <span
              v-else
              class="text-nowrap"
            >
              {{ $t('NoName') }}
            </span>
          </router-link>
        </template>

        <!--    Preview    -->
        <template #cell(preview)="{ value }">
          <b-button
            variant="flat-warning"
            class="btn-icon px-25 py-25"
            :href="`${location()}/offer/preview/${value}`"
            target="_blank"
          >
            <feather-icon
              icon="EyeIcon"
            />
          </b-button>
        </template>

        <!--    Is active    -->
        <template #cell(isActive)="{ value }">
          <b-button
            v-b-tooltip.hover
            :variant="value ? 'flat-success': 'flat-danger'"
            :title="$t(value ? 'Active' : 'InActive')"
            class="btn-icon px-25 py-25"
          >
            <feather-icon
              :icon="value ? 'CheckIcon' : 'XIcon'"
            />
          </b-button></template>

        <!--    CreatedBy    -->
        <template #cell(createdBy)="{ value }">
          <avatar
            :user="value"
            :to="{ name: 'user', params: { userId: value.id } }"
          />
        </template>

        <!--    Action    -->
        <template #cell(action)="{ item }">
          <b-dropdown
            variant="link"
            class="dropdown-icon-wrapper text-decoration-none"
            no-caret
          >
            <template
              #button-content
              class="sr-only"
            >
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle"
              />
            </template>

            <!--    Action: Duplicate    -->
            <b-dropdown-item @click="duplicate(item)">
              {{ $t('Duplicate') }}
            </b-dropdown-item>
          </b-dropdown>
        </template>

        <!--    Other    -->
        <template #cell()="row">
          <table-default-cell
            :field="row.field"
            :value="row.value"
          />
        </template>

        <!--      Empty      -->
        <template #empty>
          <empty-content />
        </template>

        <!--      Table Busy      -->
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner
              class="align-middle"
              variant="primary"
            />
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import { BTable, VBTooltip } from 'bootstrap-vue'
import { CREATE_WEBSITE, GET_WEBSITE, GET_WEBSITES } from '@/@constants/mutations'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data: () => ({
    loading: false,

    fields: [
      { key: 'name', label: 'Name', visible: true },
      { key: 'preview', label: 'Preview', visible: true },
      { key: 'isActive', label: 'isActive', visible: true },
      { key: 'createdAt', label: 'CreatedAt', visible: true },
      { key: 'updatedAt', label: 'UpdatedAt', visible: true },
      { key: 'createdBy', label: 'CreatedBy', visible: true },
      { key: 'action', label: 'Action', visible: true },
    ],
    webs: [],
    newWebsite: {},
  }),
  mounted() {
    this.getFromServer()
  },
  methods: {
    location() {
      return window.location.port || window.location.origin.toString() === 'https://demo.saleswizardapp.com' ? 'https://devsw.4b-systems.com' : window.location.origin
    },
    getFromServer() {
      this.loading = true

      this.getWebsites()
    },

    async duplicate(item) {
      this.loading = true

      await this.getWebsite(item.id)

      await this.pushWebsite()
    },

    async getWebsite(id) {
      await this.$store.dispatch(`websites/${GET_WEBSITE}`, id)
        .then(res => {
          const web = res.data.item
          const { newWebsite } = this

          newWebsite.id = web.id
          newWebsite.active = web.active

          newWebsite.webName = web.name

          /*      Section: Identity             */
          newWebsite.name = web.translations[0].pageTitle
          newWebsite.logo = web.logo
          newWebsite.favicon = web.favicon

          /*      Set Section: Client Data          */
          newWebsite.isClientData = web.contactDetails

          newWebsite.fontFamily = !!web.fontFamily && web.fontFamily.length > 0 ? web.fontFamily : 'Arial'
          newWebsite.filesBannerText = !!web.filesBannerText && web.filesBannerText.length > 0 ? web.filesBannerText : this.$i18n.t('OfferFiles')

          /*      Get Section: Palette Colours          */
          newWebsite.colorMenu = !!web.menuColor && web.menuColor.length > 0 ? web.menuColor : '#000000'
          newWebsite.colorFooter = !!web.footerColor && web.footerColor.length > 0 ? web.footerColor : '#000000'
          newWebsite.colorTextFooter = !!web.footerTextColor && web.footerTextColor.length > 0 ? web.footerTextColor : '#000000'
          newWebsite.colorLinksFooter = !!web.footerLinkTextColor && web.footerLinkTextColor.length > 0 ? web.footerLinkTextColor : '#000000'
          newWebsite.colorBanner = !!web.bannerColor && web.bannerColor.length > 0 ? web.bannerColor : '#000000'
          newWebsite.colorButtons = !!web.buttonColor && web.buttonColor.length > 0 ? web.buttonColor : '#000000'
          newWebsite.colorBannerText = !!web.bannerTextColor && web.bannerTextColor.length > 0 ? web.bannerTextColor : '#000000'
          newWebsite.colorQAndASelected = !!web.questionSelectedColor && web.questionSelectedColor.length > 0 ? web.questionSelectedColor : '#000000'
          newWebsite.colorQAndASelectedText = !!web.questionSelectedTextColor && web.questionSelectedTextColor.length > 0 ? web.questionSelectedTextColor : '#000000'
          newWebsite.colorContactText = !!web.contactTextColor && web.contactTextColor.length > 0 ? web.contactTextColor : '#000000'
          newWebsite.colorVideoText = !!web.videoTextColor && web.videoTextColor.length > 0 ? web.videoTextColor : '#000000'

          /*      Get Section: Banner      */
          newWebsite.bannerTitle = web.translations[0].bannerTitle
          newWebsite.bannerDesc = web.translations[0].bannerDescription
          newWebsite.bannerLogo = web.banner

          /*      Get Section: About               */
          newWebsite.aboutEnabled = web.companyEnabled
          newWebsite.aboutBanner = web.companyBanner
          newWebsite.aboutTitle = web.translations[0].companyTitle
          newWebsite.aboutDesc = web.translations[0].companyDescription
          newWebsite.aboutOptions = web.translations[0].companyOptions

          /*      Get Section: Products                */
          newWebsite.showProductsDescription = web.showProductsDescription
          newWebsite.productTitle = web.translations[0].productTitle
          newWebsite.productDesc = web.translations[0].productDescription
          newWebsite.productDetails = web.productDetails
          newWebsite.productPrices = web.productPrices

          /*      Get Section: QAndA             */
          newWebsite.isQAndA = web.faqEnabled
          newWebsite.QAndATitle = web.translations[0].faqTitle
          newWebsite.QAndADesc = web.translations[0].faqDescription
          newWebsite.QAndAOptions = web.translations[0].questionsAndAnswers

          /*      Get Section: Video                */
          newWebsite.isVideo = web.videoEnabled
          newWebsite.videoTitle = web.translations[0].videoTitle
          newWebsite.videoDesc = web.translations[0].videoDescription
          newWebsite.videoLink = web.translations[0].videoLink

          /*      Get Section: Opinion                */
          newWebsite.isOpinion = web.opinionsEnabled
          newWebsite.opinionTitle = web.translations[0].opinionTitle
          newWebsite.opinionDesc = web.translations[0].opinionDescription
          newWebsite.opinionOptions = web.offerWebTemplateOpinionItems

          /*      Get Section: Contact    */
          newWebsite.contactTitle = web.translations[0].contactTitle
          newWebsite.contactDesc = web.translations[0].contactDescription
          newWebsite.contactPhone = web.contactPhone
          newWebsite.contactEmail = web.contactEmail
          newWebsite.contactAvatar = web.contactAvatar
          newWebsite.contactIsDetails = web.contactCompanyDetails

          /*      Get Section: Attachments          */
          newWebsite.isAttachments = web.attachmentsEnabled
          newWebsite.attachmentsPDF = web.generatePdf
          newWebsite.attachmentsDOC = web.additionalFiles

          /*      Get Section: Footer          */
          newWebsite.footerEnabled = web.footerEnabled ? web.footerEnabled : false
          newWebsite.footerShowAddress = web.footerShowAddress ? web.footerShowAddress : false
          newWebsite.footerAddress1 = web.footerAddress1 ? web.footerAddress1 : ''
          newWebsite.footerAddress2 = web.footerAddress2 ? web.footerAddress2 : ''
          newWebsite.footerAddress3 = web.footerAddress3 ? web.footerAddress3 : ''
          newWebsite.footerLinks = Array.isArray(web.footerLinks) && web.footerLinks?.length ? web.footerLinks : [{ name: '', link: '' }, { name: '', link: '' }, { name: '', link: '' }]
          newWebsite.footerDescription = web.footerDescription ? web.footerDescription : ''
          newWebsite.footerCopyright = web.footerCopyright ? web.footerCopyright : ''
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },

    getWebsites() {
      this.webs = []

      this.$store.dispatch(`websites/${GET_WEBSITES}`)
        .then(res => {
          const websites = res.data.items

          websites.forEach(el => {
            if (!!el.translations && el.translations.length > 0) {
              this.webs.push({
                id: el.id,
                isActive: el.active,
                name: el.name,
                preview: el.id,
                createdAt: el.createdAt,
                updatedAt: el.updatedAt,
                createdBy: el.createdBy,
              })
            }
          })

          this.loading = false
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },

    prepareData() {
      const newWebsite = {
        active: this.newWebsite.active,
        name: `${this.newWebsite.webName} ${this.newWebsite.id}`,

        /*      Set Section: Identity          */
        logo: this.newWebsite.logo,
        favicon: this.newWebsite.favicon,

        /*      Set Section: Client Data          */
        contactDetails: this.newWebsite.isClientData,

        /*      Set Section: Font      */
        fontFamily: this.newWebsite.fontFamily,
        /*      Set Section: Files Banner      */
        filesBannerText: this.newWebsite.filesBannerText,

        /*      Set Section: Palette Colours      */
        menuColor: this.newWebsite.colorMenu,
        footerColor: this.newWebsite.colorFooter,
        footerTextColor: this.newWebsite.colorTextFooter,
        footerLinkTextColor: this.newWebsite.colorLinksFooter,
        bannerColor: this.newWebsite.colorBanner,
        buttonColor: this.newWebsite.colorButtons,
        bannerTextColor: this.newWebsite.colorBannerText,
        questionSelectedColor: this.newWebsite.colorQAndASelected,
        questionSelectedTextColor: this.newWebsite.colorQAndASelectedText,
        contactTextColor: this.newWebsite.colorContactText,
        videoTextColor: this.newWebsite.colorVideoText,

        /*      Set Section: Banner              */
        banner: this.newWebsite.bannerLogo,

        /*      Get Section: About               */
        companyEnabled: this.newWebsite.aboutEnabled,
        companyBanner: this.newWebsite.aboutBanner,

        /*      Set Section: Products             */
        showProductsDescription: this.newWebsite.showProductsDescription,
        productDetails: this.newWebsite.productDetails,
        productPrices: this.newWebsite.productPrices,

        /*      Set Section: QAndA             */
        faqEnabled: this.newWebsite.isQAndA,

        /*      Set Section: Video                */
        videoEnabled: this.newWebsite.isVideo,

        /*      Set Section: Opinion                */
        opinionsEnabled: this.newWebsite.isOpinion,
        offerWebTemplateOpinionItems: this.newWebsite.opinionOptions,

        /*      Set Section: Contact              */
        contactAvatar: this.newWebsite.contactAvatar,
        contactCompanyDetails: this.newWebsite.contactIsDetails,
        contactPhone: this.newWebsite.contactPhone,
        contactEmail: this.newWebsite.contactEmail,

        /*      Set Section: Attachments          */
        attachmentsEnabled: this.newWebsite.isAttachments,
        generatePdf: this.newWebsite.attachmentsPDF,
        additionalFiles: this.newWebsite.attachmentsDOC,

        /*      Set Section: Footer          */
        footerEnabled: this.newWebsite.footerEnabled,
        footerShowAddress: this.newWebsite.footerShowAddress,
        footerAddress1: this.newWebsite.footerAddress1,
        footerAddress2: this.newWebsite.footerAddress2,
        footerAddress3: this.newWebsite.footerAddress3,
        footerLinks: this.newWebsite.footerLinks,
        footerDescription: this.newWebsite.footerDescription,
        footerCopyright: this.newWebsite.footerCopyright,

        translations: [
          {
            locale: 'pl_pl',

            /*      Set Section: Identity          */
            pageTitle: this.newWebsite.name,

            /*      Set Section: Banner               */
            bannerTitle: this.newWebsite.bannerTitle,
            bannerDescription: this.newWebsite.bannerDesc,

            /*      Set Section: About                */
            companyTitle: this.newWebsite.aboutTitle,
            companyDescription: this.newWebsite.aboutDesc,
            companyOptions: this.newWebsite.aboutOptions,

            /*      Set Section: Products             */
            productTitle: this.newWebsite.productTitle,
            productDescription: this.newWebsite.productDesc,

            /*      Set Section: QAndA                */
            faqTitle: this.newWebsite.QAndATitle,
            faqDescription: this.newWebsite.QAndADesc,
            questionsAndAnswers: this.newWebsite.QAndAOptions,

            /*      Set Section: Video                */
            videoTitle: this.newWebsite.videoTitle,
            videoDescription: this.newWebsite.videoDesc,
            videoLink: this.newWebsite.videoLink ? this.newWebsite.videoLink.replaceAll('youtube.com', 'youtube-nocookie.com').replaceAll('watch?v=', 'embed/') : '',

            /*      Set Section: Customers Opinion    */
            opinionTitle: this.newWebsite.opinionTitle,
            opinionDescription: this.newWebsite.opinionDesc,

            /*      Set Section: Contact          */
            contactTitle: this.newWebsite.contactTitle,
            contactDescription: this.newWebsite.contactDesc,
            contactDetails: this.newWebsite.contactDetails,
          },
        ],
      }

      /*      Push: Identity - Logo      */
      if (newWebsite?.logo?.token) newWebsite.logo = newWebsite.logo.token

      /*      Push: Identity - Favicon      */
      if (newWebsite?.favicon?.token) newWebsite.favicon = newWebsite.favicon.token

      /*      Push: Banner - IMG      */
      if (newWebsite?.banner?.token) newWebsite.banner = newWebsite.banner.token

      /*      Push: BannerLogo - IMG      */
      if (newWebsite?.bannerLogo?.token) newWebsite.bannerLogo = newWebsite.bannerLogo.token

      /*      Push: AboutBanner - IMG      */
      if (newWebsite?.aboutBanner?.token) newWebsite.aboutBanner = newWebsite.aboutBanner.token

      /*      Push: About Banner - IMG      */
      if (newWebsite?.companyBanner?.token) newWebsite.companyBanner = newWebsite.companyBanner.token

      /*      Push: Contact - IMG      */
      if (newWebsite?.contactAvatar?.token) newWebsite.contactAvatar = newWebsite.contactAvatar.token

      delete newWebsite.id

      newWebsite.offerWebTemplateOpinionItems.forEach((opinion, i) => {
        delete newWebsite.offerWebTemplateOpinionItems[i].createdAt
        delete newWebsite.offerWebTemplateOpinionItems[i].createdBy
        delete newWebsite.offerWebTemplateOpinionItems[i].updatedAt
        delete newWebsite.offerWebTemplateOpinionItems[i].updatedBy

        newWebsite.offerWebTemplateOpinionItems[i].avatar = opinion.avatar.token

        opinion.translations.forEach((_, n) => {
          delete newWebsite.offerWebTemplateOpinionItems[i].translations[n].createdAt
          delete newWebsite.offerWebTemplateOpinionItems[i].translations[n].createdBy
          delete newWebsite.offerWebTemplateOpinionItems[i].translations[n].updatedAt
          delete newWebsite.offerWebTemplateOpinionItems[i].translations[n].updatedBy
        })
      })

      return newWebsite
    },

    async pushWebsite() {
      const newWebsite = this.prepareData()

      this.$store.dispatch(`websites/${CREATE_WEBSITE}`, newWebsite)
        .then(() => {
          this.showToast('success', this.$i18n.t('success.offerWebsiteDuplicated'), this.$i18n.t('success.duplicated'))

          this.getWebsites()
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
        .finally(() => { this.loading = false })
    },
  },
}
</script>
